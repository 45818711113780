import React from 'react';
import { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import './App.css';
import { HomePage } from './pages/home';
import { AboutPage } from './pages/about';
import { BlogPage } from './pages/blog';
import { PostPage } from './pages/post';
import { ProductsPage } from './pages/products';
import PrivacyStatementPage from './pages/privacystatement';
import TermsOfUsePage from './pages/termsofuse';
import { FAQPage } from './pages/faq';
import { PageNotFoundPage } from './pages/404';
import PricingPage from './pages/pricing';
import Subscribe from './pages/subscription';
import ResponsiveAppBar from './components/navbar';
import NewsletterPage from './pages/newsletter';
import { AccountConfirmationPage } from './pages/account_confirmation';
import { ConfirmationCodeExpired } from './pages/confirmation_code_expired';
import PaymentStatus from './pages/payment_status';
import Footer from './components/footer';
import RequestDemo from './pages/request_demo';
import { PUBLIC_PATH, IS_PROD } from './components/constants';

if (IS_PROD === true) {
	// Disable console methods
	console.log = () => {};
	console.error = () => {};
	console.debug = () => {};
	console.log('Console methods disabled');
}

const primary = {
	light: '#9EF9B1',
	main: '#2CEA56',
	dark: '#23943B',
	button_and_highlights: '#A1F8B4',
	bright_green: '#3BE85F',
};

const secondary = {
	light: '#F5F5F5',
	main: '#868683',
	dark: '#39393A',
};

const background = {
	darkgrey: '#D7D7D7',
	default: '#F5F5F5',
	plain: '#FFFFFF',
};

const theme = createTheme({
	palette: {
		primary: primary,
		secondary: secondary,
		background: background,
	},
	typography: {
		fontFamily: 'Montserrat',
		h1: {
			// fontWeight: 600,
			fontSize: '80px', // 5rem
			// lineHeight: '84.72px',
			'@media (max-width: 900px)': {
				//md
				fontSize: '64px',
			},
			'@media (max-width: 600px)': {
				fontSize: '56px',
			},
		},
		h2: {
			fontSize: '40px', // 2.5rem
			'@media (max-width: 900px)': {
				//md
				fontSize: '32px',
			},
			'@media (max-width: 600px)': {
				fontSize: '28px',
			},
		},
		h3: {
			fontSize: '30px', // 1.875rem
			'@media (max-width: 900px)': {
				//md
				fontSize: '24', // 1.5rem
			},
			'@media (max-width: 600px)': {
				fontSize: '18px',
			},
		},
		h4: {
			// fontWeight: 400,
			fontSize: '25px', // 1.5625rem
			// lineHeight: '24.38px',
			'@media (max-width: 900px)': {
				//md
				fontSize: '20', // 1.25rem
			},
		},
		h5: {
			// fontWeight: 700,
			fontSize: '22px', // 1.375rem
			// lineHeight: '22px',
			'@media (max-width: 900px)': {
				//md
				fontSize: '18px', // 1.125rem
			},
		},
		h6: {
			// fontWeight: 400,
			fontSize: '18px', // 1.125rem
			// lineHeight: '22px',
			'@media (max-width: 900px)': {
				//md
				fontSize: '18px', // 1.5rem
			},
		},
		body1: {
			fontSize: '18px', // 1.05rem
			'@media (max-width: 900px)': {
				//md
				fontSize: '16', // 1rem
			},
		},
		navbar: {
			fontWeight: 500,
			fontSize: '22px',
			lineHeight: '26.82px',
			color: '#39393A',
		},
		homepageHeader: {
			// homepage header
			fontFamily: 'Montserrat',
			fontWeight: 700,
			fontSize: '80px',
			lineHeight: '84.72px',
		},
		pageHeader: {
			// page headers
			fontFamily: 'Montserrat',
			fontWeight: 400,
			fontSize: '40px',
			lineHeight: '48.76px',
		},
		fatHeader: {
			// fat headers
			fontWeight: 600,
			fontSize: '30px',
			lineHeight: '36.57px',
		},
		teamHeader: {
			// team card headers
			fontWeight: 700,
			fontSize: '40px',
			lineHeight: '36.57px',
		},
		subtitle: {
			fontWeight: 500,
			fontSize: '25px',
			lineHeight: '30.48px',
		},
		blogPostSubTitle: {
			fontWeight: 500,
			fontSize: '25px',
			lineHeight: '30.48px',
			display: 'inline',
		},
		blogPostTitle: {
			fontWeight: 600,
			fontSize: '35px',
			lineHeight: '42.67px',
			display: 'inline',
		},
	},
	shape: {
		borderRadius: 20,
	},
	components: {
		MuiAccordion: {
			defaultProps: {
				elevation: 0,
			},
		},
		MuiIconButton: {
			variants: [
				{
					props: { variant: 'socialMedia' },
					style: {
						backgroundColor: '#ffffff',
						borderRadius: '50%',
						fontSize: '2rem',
						'&:active, &:focus, &:hover': {
							backgroundColor: '#ffffff',
							color: '#2CEA56',
						},
					},
				},
			],
		},
		MuiButton: {
			styleOverrides: {
				// ({ //ownerState, theme }) =>
				root: () => ({
					letterSpacing: '0.01em', // dirty hack to offset bold font on hover
					'&:active, &:focus, &:hover': {
						fontWeight: 'bold',
						letterSpacing: 0,
					},
				}),
			},
			variants: [
				{
					props: { variant: 'green' },
					style: {
						backgroundColor: '#9EF9B1',
						'&:active, &:focus, &:hover': {
							backgroundColor: '#a1f8b4',
							opacity: 0.4,
							color: 'black',
						},
					},
				},
				{
					props: { variant: 'containedOutlined', color: 'whiteToGreen' },
					style: {
						color: secondary.dark,
						backgroundColor: background.plain,
						letterSpacing: '0.02em', // dirty hack to offset bold font on hover
						'&:active, &:focus, &:hover': {
							color: background.plain,
							backgroundColor: 'transparent',
							boxShadow: `inset 0 0 0 2px ${primary.main}`,
							fontWeight: 'bold',
							letterSpacing: 0,
						},
					},
				},
			],
		},
		MuiLink: {
			variants: [
				{
					props: { variant: 'whiteToGreenUnderline' }, // do not support color as props
					style: {
						fontFamily: 'Montserrat',
						fontWeight: 400,
						fontSize: '18px',
						lineHeight: '22px',
						display: 'inline',
						color: background.plain,
						textDecorationColor: background.plain,
						textDecorationLine: 'solid',
						'&:active, &:focus, &:hover': {
							color: primary.main,
							textDecorationColor: primary.main,
						},
					},
				},
			],
		},
	},
});

function getUserInfo() {
	const user = sessionStorage.getItem('user') || localStorage.getItem('user');
	return JSON.parse(user);
}

export class App extends Component {
	constructor() {
		super();
		this.state = {};
	}

	componentDidMount() {
		const user = getUserInfo();
		this.setState({ ...this.state, user });
	}

	render() {
		const basename = '/' === PUBLIC_PATH ? PUBLIC_PATH : new URL(PUBLIC_PATH).pathname;
		return (
			<ThemeProvider theme={theme}>
				<CssBaseline />
				<Router basename={basename}>
					<div id='App'>
						<ResponsiveAppBar />
						<Switch>
							<Route path='/about'>
								<AboutPage />
							</Route>
							<Route path='/blog/:slug' exact>
								<PostPage />
							</Route>
							<Route path='/blog'>
								<BlogPage />
							</Route>
							<Route path='/privacy_statement'>
								<PrivacyStatementPage />
							</Route>
							<Route path='/terms_of_use'>
								<TermsOfUsePage />
							</Route>
							<Route path='/faq'>
								<FAQPage />
							</Route>
							<Route path='/products'>
								<ProductsPage />
							</Route>
							<Route path='/pricing'>
								<PricingPage />
							</Route>
							<Route path='/subscribe'>
								<Subscribe />
							</Route>
							<Route path='/newsletter-signup'>
								<NewsletterPage />
							</Route>
							<Route exact path='/'>
								<HomePage />
							</Route>
							<Route exact path='/home'>
								<HomePage />
							</Route>
							<Route path='/confirmed_account'>
								<AccountConfirmationPage />
							</Route>
							<Route path='/confirmation_code_expired'>
								<ConfirmationCodeExpired />
							</Route>
							<Route path='/payment_status'>
								<PaymentStatus />
							</Route>
							<Route path='/request-demo'>
								<RequestDemo />
							</Route>
							<Route path='*'>
								<PageNotFoundPage />
							</Route>
						</Switch>
						<Footer />
					</div>
				</Router>
			</ThemeProvider>
		);
	}
}

export default App;

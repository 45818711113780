import spic from '../../imgs/profiles/eu.webp';
import ppic from '../../imgs/profiles/paul.webp';
import wpic from '../../imgs/profiles/william.webp';
import opic from '../../imgs/profiles/olu.webp';
import tpic from '../../imgs/profiles/til.webp';
import mpic from '../../imgs/profiles/matson.webp';
import npic from '../../imgs/profiles/nanda.webp';
import ypic from '../../imgs/profiles/yryskeldi.webp';
import mapic from '../../imgs/profiles/madalina.webp';
import phpic from '../../imgs/profiles/placeholder.webp';
import aapic from '../../imgs/profiles/aafia.webp';
import atpic from '../../imgs/profiles/atilla.webp';
import joapic from '../../imgs/profiles/joaymah.webp';
import jppic from '../../imgs/profiles/joep.webp';

/* About Page Summary */
export const aboutSummary =
	'We are an interdisciplinary team expert in organizing, understanding, and analyzing data related to sustainability. We provide services to companies and NGOs who want data to guide investment and policy for the benefit of the planet.  Our international team brings together expertise in finance, economics, business, computer science, artificial intelligence, and sustainability.';

/* Team members description /about */
export const aboutExecutiveBoard = [
	{
		pic: ppic,
		title: 'Paul Rosler',
		subtitle: 'Founder & CEO',
		contact_links: [
			'https://linkedin.com/in/paul-r%C3%B6sler-02063b173/',
			'https://github.com/PRosler',
		],
		description:
			'Paul came up with the idea behind Oxari. He graduated with the Banking & Finance Master at Utrecht University. He also studied Sustainable Economics at the University of Oldenberg and worked for the sustainable bank Triodos. Besides his professional life, Paul plays classical guitar, travels, and hikes in the mountains of Mexico, where he currently lives.',
	},

	{
		pic: wpic,
		title: 'William Vuijk',
		subtitle: 'Founder & CFO',
		contact_links: ['https://www.linkedin.com/in/william-vuijk-055295a7/'],
		description:
			'William handles the financial and law issues at Oxari. He is part of the Buisiness Development and Entrepreneurship Master at Utrecht University. He is the founder of Guillame Ties and also works for a sustainable crowdfunding platform. In his free time, William skis, reads management books, and makes new additions to his wine collection.',
	},

	{
		pic: opic,
		title: 'Olusanmi Hundogan',
		subtitle: 'Founder & CIO',
		contact_links: ['https://www.linkedin.com/in/olusanmi-hundogan/', 'https://github.com/Olu93'],
		description:
			'Olu is a passionate tech enthusiast with a business informatics and artificial intelligence background. He is an experienced developer possessing knowledge of a wide range of subfields within Computer Science and Machine Learning. Within Oxari, Olu is tasked with overseeing the development of the machine learning pipelines as well as other subsequent projects.  Olu worked for a total of 6 years for Robert Bosch. He is not only a full-stack engineer but also very experienced with Deep Learning and NLP. When he is not coding, Olu goes bouldering, plays chess, and codes a little more.',
	},

	{
		pic: spic,
		title: 'Sorin Dragan',
		subtitle: 'Founder & CTO',
		contact_links: ['https://www.linkedin.com/in/sorindragan/', 'https://github.com/sorindragan'],
		description:
			'Sorin is a versatile Software Engineer with a M.Sc. Degree in Artificial Intelligence awarded by Utrecht University. There, he completed his thesis in partnership with CWI, the research institute where both Python and Dijkstra’s algorithm were invented, and served as a teaching assistant for two Master level courses. He also holds a B.Eng. Degree in Computer Science from the Politehnica University of Bucharest. Sorin previously worked as a Machine Learning Developer as well as a Software Developer Engineer for AWS, where he was directly involved in the launch of Amazon CodeCatalyst. Outside the work environment, Sorin explores international cinema, cooks Italian food, and watches Premier League.',
	},
];

export const aboutSales = [
	{
		pic: jppic,
		title: 'Joep Corstiaens en',
		subtitle: 'Sales Director',
		contact_links: ['https://www.linkedin.com/in/joepyrm/'],
		description: '',
	},
];

export const aboutDev = [
	{
		pic: tpic,
		title: 'Til Dietrich',
		subtitle: 'Environmental Economist & Data Scientist',
		contact_links: ['https://www.linkedin.com/in/tildietrich', 'https://github.com/tildtr'],
		description:
			'Til is currently involved in developing econometric models for scientific publications. He previously worked at the sustainability bank Triodos and graduated from Nova SBE with a M.Sc. in Economics, focusing on environmental and behavioural economics as well as econometrics. Being involved in both decision-making and methodology developments, Til plays a central role at Oxari. He is passionate about environmental issues, with a strong concern for social equity in the transition towards a more sustainable society. Til loves diving into the philosophy of human behaviour, cooking and surfing on the Portuguese coast, where he currently resides.',
	},

	{
		pic: mpic,
		title: 'Matson Ho',
		subtitle: 'Full Stack Software Developer',
		contact_links: ['https://www.linkedin.com/in/matsonho/', 'https://github.com/nostam'],
		description:
			'Matson is an aspiring full-stack developer. He graduated in Economics from the University of Groningen. He started coding during his university years and has been hooked ever since. In his spare time, he enjoys reading manga and water sports.',
	},

	{
		pic: ypic,
		title: 'Yryskeldi Azamatov',
		subtitle: 'Junior Software Developer',
		contact_links: ['https://www.linkedin.com/in/4zamatov/', 'https://github.com/keyhot'],
		description: 'Yryskeldi is a student and a padawan.',
	},

	{
		pic: mapic,
		title: 'Oana Madalina Fron',
		subtitle: 'Junior Software Developer',
		contact_links: ['https://www.linkedin.com/in/madalina-fron', 'https://github.com/madafron02'],
		description: 'Madalina is a student and a dancer.',
	},

	{
		pic: npic,
		title: 'Nanda Vikas Konduru',
		subtitle: 'Associate Research Master Student',
		contact_links: ['https://www.linkedin.com/in/nandavikas/', 'https://github.com/nandavikas'],
		description:
			'Nanda is a student of Data Science at University of Trier. He is interested in creating products by using Artificial Intelligence and Web Development. Outside of work, he is interested in travelling, playing outdoor games.',
	},

	{
		pic: phpic,
		title: ' Hemanth Kumar Gutlapalli',
		subtitle: 'Associate Research Master Student',
		contact_links: [
			'https://www.linkedin.com/in/hemanthgutlapalli/',
			'https://github.com/gutlapallihemanth',
		],
		description:
			'Hemanth is a student of Data Science at University of Trier. He is passionate about deriving actionable insights from complex datasets.',
	},
];

export const aboutSustainability = [
	{
		pic: atpic,
		title: 'Attila Czudar',
		subtitle: 'Sustainability Strategic Advisor',
		contact_links: ['https://www.linkedin.com/in/attila-czudar-6480901/'],
		description: '',
	},

	{
		pic: aapic,
		title: 'Aafia Tehrim',
		subtitle: 'Sustainability Analyst',
		contact_links: ['https://www.linkedin.com/in/aafia-tehrim-36856920a/'],
		description: '',
	},

	{
		pic: joapic,
		title: 'Joaymah Papeleras',
		subtitle: 'Sustainability Analyst',
		contact_links: [],
		description: '',
	},
];

import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Container, Typography, Grid, Box, Link, Card, CardMedia } from '@mui/material';
import { showAuthors } from '../misc/utils';

const BLOG_URL = process.env.REACT_APP_STRAPI_BLOG_URL || 'http://localhost:1337/api';
const DO_PREFIX = process.env.REACT_APP_DO_PREFIX || '';

export function BlogPage() {
	let [posts, setPosts] = useState([]);

	useEffect(() => {
		getPosts();
	}, []);

	async function getPosts() {
		const fields = ['title', 'sub', 'slug', 'description', 'publishedAt'];
		try {
			const response = await fetch(
				`${BLOG_URL}/posts?sort=publishedAt:desc&${fields
					.map((key, idx) => `fields[${idx}]=${key}`)
					.toString()
					.split(',')
					.join('&')}`
			);


			const { data } = await response.json();
			setPosts(data);
		} catch (err) {
			console.error(err);
		}
	}

	function readPost() {}

	return (
		<Box>
			<Helmet key='blogs'>
				<meta
					charSet='utf-8'
					name='description'
					content='Oxari Blog with articles about finance, sustainability, green investment.'
				/>
				<title>Oxari Blog</title>
			</Helmet>
			<Container sx={{ paddingBottom: 6 }}>
				<Box paddingTop={6} paddingBottom={3}>
					<Typography variant='pageHeader'>Blog</Typography>
				</Box>

				<Box paddingBottom={6} width='70%'>
					<Typography variant='h4'>Read our latest blogposts.</Typography>
				</Box>

				<Box
					sx={{ paddingTop: 2, paddingBottom: 2, display: 'flex', flexDirection: 'column', gap: 2 }}
				>
					{posts &&
						posts.map((post, index) => (
							<Box key={index} onClick={() => readPost(index)} p={2} bgcolor='white'>
								<Link
									// tag={CustomNavLink}
									// activeClassName='active'
									href={`${DO_PREFIX}/blog/${post.attributes.slug}`}
									underline='none'
									sx={{
										color: 'black',
										'&:hover': { color: 'black', textDecoration: 'none' },
									}}
								>
									<Container>
										<Grid container spacing={2}>
											<Grid item md={6} paddingRight={2}>
												<Box paddingBottom={2}>
													<Typography variant='fatHeader'>{post.attributes.title}</Typography>
												</Box>

												<Box paddingBottom={3}>
													<Typography variant='h4'>{post.attributes.description}</Typography>
												</Box>

												<Box>
													<Typography variant='h6'>
														By {showAuthors(post.attributes.authors, post.attributes.createdBy)}
													</Typography>
												</Box>
											</Grid>

											<Grid item md={6}>
												<Card sx={{ borderRadius: '0%', borderColor: 'primary.main' }}>
													<CardMedia
														component='img'
														image={post.attributes.picture.data.attributes.formats.small.url}
														alt={post.attributes.slug}
													/>
												</Card>
											</Grid>
										</Grid>
									</Container>
								</Link>
							</Box>
						))}
				</Box>
			</Container>
		</Box>
	);
}

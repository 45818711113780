import { useEffect, useRef, useState } from 'react';
import Typography from '@mui/material/Typography';
import { styled, keyframes } from '@mui/system';

const duration = 4;

const tickerEnteringAnimation = keyframes`
from, ${(duration / (duration + 1)) * 100}%  {
  transform: translateY(100%);
  opacity: 0;
}
${(duration / (duration + 1)) * 100}% {
  transform: translateY(100%);
  opacity: 0.7;
}
to {
  transform: translateY(0%);
  opacity: 1;
}
`;

const tickerLeavingAnimation = keyframes`
from {
  transform: translateY(0%);
  opacity: 1;
}
${(duration / (duration + 1)) * 100}% {
  transform: translateY(0);
  opacity: 1;
}
to {
  transform: translateY(-100%);
  opacity: 0.7 ;
}
`;
const VerticalTickerWrapper = styled('div')`
	display: flex;
`;

const VerticalTickerContainer = styled('div', { shouldForwardProp: (prop) => prop })(
	({ theme, variant, fontWeight }) => ({
		...theme.typography[variant],
		fontWeight,
		position: 'relative',
		width: '100%',
		height: theme.typography[variant].fontSize,
		overflow: 'hidden',
	})
);

const VerticalTickerList = styled('ul', { shouldForwardProp: (prop) => prop })(() => ({
	position: 'relative',
	listStyle: 'none',
	padding: 0,
	height: '100%',
}));

const VerticalTickerWord = styled('li', { shouldForwardProp: (prop) => prop })(
	({ entering, leaving }) => ({
		position: 'absolute',
		top: `${0}px`,
		opacity: entering | leaving ? 1 : 0,
		animation: `${
			entering
				? `${tickerEnteringAnimation} ${duration}s 1 linear`
				: leaving
				? `${tickerLeavingAnimation} ${duration}s 1 linear`
				: 'none'
		}`,
	})
);

const VerticalTicker = ({ items, staticSuffix = '', variant, fontWeight }) => {
	const idxRef = useRef(0);
	const [entering, setEntering] = useState(Array(items.length).fill(false));
	const [leaving, setLeaving] = useState([...Array(items.length - 1).fill(false), true]); // starts with the last item

	useEffect(() => {
		const tick = () => {
			const currentIdx = idxRef.current;
			const nextIdx = currentIdx + 1 === items.length ? 0 : currentIdx + 1;
			setLeaving((prevState) => prevState.map((v, i) => i === currentIdx));
			setEntering((prevState) => prevState.map((v, i) => i === nextIdx));
			idxRef.current = nextIdx;
		};
		const tickerInterval = setInterval(tick, duration * 1000);
		return () => clearInterval(tickerInterval);
	}, [items.length]);

	return (
		<VerticalTickerWrapper>
			<Typography variant={variant} fontWeight={fontWeight} mr={1}>
				{staticSuffix}
			</Typography>
			<VerticalTickerContainer variant={variant} fontWeight={fontWeight}>
				<VerticalTickerList>
					{items.map((item, i) => (
						<VerticalTickerWord
							key={`verticalTickerWord${i}`}
							entering={entering[i]}
							leaving={leaving[i]}
							variant={variant}
							fontWeight={fontWeight}
						>
							{item}
						</VerticalTickerWord>
					))}
				</VerticalTickerList>
			</VerticalTickerContainer>
		</VerticalTickerWrapper>
	);
};

export default VerticalTicker;

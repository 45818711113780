import React from 'react';
// import { Container, Row, Col, Jumbotron } from "reactstrap";
import { Container, Row, Col } from 'reactstrap';
import { useState } from 'react';
import { useEffect } from 'react';
import './css/404.css';

const VISCERA_URL = process.env.REACT_APP_BACKEND_URL;

const ValidateSession = async (session_id) => {
	var myHeaders = new Headers();
	myHeaders.append('Content-Type', 'application/json');

	var raw = JSON.stringify({
		session_id: session_id,
	});

	var requestOptions = {
		method: 'POST',
		headers: myHeaders,
		body: raw,
		redirect: 'follow',
	};

	try {
		let response = await fetch(
			`${VISCERA_URL}/sineservo/validate-session-update-db`,
			requestOptions
		);
		const responseBody = await response.json();
		return responseBody;
	} catch (error) {
		console.log(error);
	}
};

// const jumbotronStyle = {
//     marginBottom: "0px",
//     backgroundColor: "lightgreen"
//   }

export default function PaymentStatus() {
	let [StripeMessage, setStripeMessage] = useState('');
	// let [success, setSuccess] = useState(false);
	let [userResponse, setUserResponse] = useState({});
	const query = new URLSearchParams(window.location.search);
	const sessionId = query.get('session_id');

	useEffect(() => {
		if (query.get('success')) {
			// setSuccess(true);
			setStripeMessage('Payment was sucessful');
			ValidateSession(sessionId).then((response) => {
				setUserResponse(response);
			});
		}

		if (query.get('canceled')) {
			// setSuccess(false);
			setStripeMessage("Order canceled -- continue to shop around and checkout when you're ready.");
		}
		// eslint-disable-next-line
	}, []);

	return (
		// <Jumbotron
		//   style={jumbotronStyle}
		// >
		<div className='jumbotronStyle jumbotron'>
			<Container>
				<Row>
					<Col sm={3}></Col>
					<Col>
						<h1 className='text-center'>{StripeMessage}</h1>
						<h2 className='text-center'>{userResponse.message}</h2>
						<h2 className='text-center'>Your new plan: {userResponse?.payload?.plan_type}</h2>
						<h2 className='text-center'>Your new credits: {userResponse?.payload?.n_credits}</h2>
					</Col>
					<Col sm={3}></Col>
				</Row>
			</Container>
		</div>
	);
}

import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import './css/404.css';

// const jumbotronStyle = {
//     marginBottom: "0px",
//     backgroundColor: "lightgreen"
//   }

export class ConfirmationCodeExpired extends React.Component {
	render() {
		return (
			// <Jumbotron
			//   style={jumbotronStyle}
			// >
			<div className='jumbotronStyle jumbotron'>
				<Container>
					<Row>
						<Col sm={3}></Col>
						<Col>
							<h1 className='text-center'>Oops, the link you have clicked is expired.</h1>
							<h2 className='text-center'>
								We have sent you another email with a new confirmation code.
							</h2>
							<p className='text-center'>Please check your inbox.</p>
						</Col>
						<Col sm={3}></Col>
					</Row>
				</Container>
			</div>
		);
	}
}

import React from 'react';
import { Row, Col, Container } from 'reactstrap';
import logo from '../imgs/oxari.webp';
import { animateScroll as scroll } from 'react-scroll';
import './css/privacystatement.css';
import { privacyStatementContent } from '../misc/text/privacystatement';

class PrivacyStatementPage extends React.Component {
	constructor() {
		super();
		this.scrollToTop = this.scrollToTop.bind(this);
	}

	scrollToTop() {
		scroll.scrollToTop();
	}

	componentDidMount() {
		this.scrollToTop();
	}

	render() {
		return (
			<Container className='my-5'>
				<Row id='privacystatement-intro'>
					<Col lg='6'>
						<img className='img-fluid d-none d-lg-block' src={logo} alt='Logo' />
					</Col>
					<Col sm='12' lg='6' className='d-flex align-items-center'>
						<h5 className='section-paragraph px-3 px-md-auto'>
							This privacy policy will explain how Oxari uses the personal data we collect from you
							when you use our website.
						</h5>
					</Col>
				</Row>

				<Row className='py-4'>
					{privacyStatementContent.map((el, i) => (
						<div key={'privacyStatement' + i} className='w-100'>
							<h2 className='page-title'>{el.title}</h2>
							<div className='page-text'>
								{el.body.map((list, j) => {
									if (typeof list === 'string') return <p key={'privacyCtx' + j}>{list}</p>;
									return (
										<ul key={'privacyCtx' + j}>
											{list.map((listitem, k) => (
												<li key={'privacyList' + k}>{listitem}</li>
											))}
										</ul>
									);
								})}
							</div>
							<hr
								style={{
									height: '4px',
									margin: '32px 0px',
								}}
							/>
						</div>
					))}
				</Row>
			</Container>
		);
	}
}

export default PrivacyStatementPage;

import React, { useState } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import HorizantalStepper from '../components/horizantal_stepper';
import BillingForm from '../components/billing_form';
import { subscriptions } from '../misc/text/subscription';

const theme = createTheme();

const VISCERA_URL = process.env.REACT_APP_BACKEND_URL;

export default function Subscribe() {
	const [stepNumber, setStepNumber] = useState(0);
	const [backDisbaled, setBackDisabled] = useState(true);
	// const [userDetails, setUserDetails] = useState({});

	const params = new URLSearchParams(window.location.search);
	const planType = params.get('type');
	const planTypeDetails = subscriptions.filter((subscription) => subscription.type === planType)[0];

	const onClickPaymentDetails = (price_id, user_id, plan_type, n_credits) => {
		var myHeaders = new Headers();
		myHeaders.append('Content-Type', 'application/json');

		var raw = JSON.stringify({
			price_id: price_id,
			user_id: user_id,
			plan_type: plan_type,
			n_credits: n_credits,
		});

		var requestOptions = {
			method: 'POST',
			headers: myHeaders,
			body: raw,
			redirect: 'follow',
		};

		fetch(`${VISCERA_URL}/sineservo/create-checkout-session`, requestOptions)
			.then((response) => response.text())
			.then((result) => {
				result = JSON.parse(result);
				window.location.replace(result['payload']);
			})
			.catch((error) => console.log('error', error));
	};

	const handleSubmit = (event) => {
		event.preventDefault();

		// if (stepNumber === 2) { window.location.replace(planTypeDetails.payment_link); return }
		if (stepNumber === 2) {
			onClickPaymentDetails(
				planTypeDetails.price_id,
				'737709a6-4627-4ea6-9c9d-e762eb606a2e',
				planTypeDetails.name,
				planTypeDetails.budget
			);
			return;
		}

		setStepNumber(stepNumber + 1);
		setBackDisabled(false);
	};

	const onClickBack = (event) => {
		event.preventDefault();
		setStepNumber(stepNumber - 1);
		if (stepNumber === 0) {
			setBackDisabled(true);
		}
	};

	const containerWidth = stepNumber === 0 ? 'xs' : 'xl';

	return (
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={theme}>
				<HorizantalStepper stepNumber={stepNumber} />
				<Container component='main' maxWidth={containerWidth} sx={{ marginBottom: '50px' }}>
					<CssBaseline />
					<Box
						sx={{
							marginTop: 5,
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
						}}
					>
						<Box noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
							{stepNumber === 0 ? (
								<BillingForm />
							) : stepNumber === 1 ? (
								<p> Work in progress </p>
							) : (
								<p> Proceed to payment </p>
							)}
						</Box>
					</Box>
				</Container>
				<Box display='flex' justifyContent='center' alignItems='center'>
					<Button
						type='submit'
						variant='contained'
						color='success'
						disabled={backDisbaled}
						sx={{ mt: 1, mb: 1, minWidth: '183px', marginLeft: '4px', marginRight: '4px' }}
						justifyContent='center'
						onClick={onClickBack}
					>
						Back
					</Button>
					<Button
						type='submit'
						variant='contained'
						color='success'
						sx={{ mt: 1, mb: 1, minWidth: '183px', marginLeft: '4px', marginRight: '4px' }}
						onClick={handleSubmit}
						justifyContent='flex-start'
					>
						{parseInt(stepNumber) === 2 ? 'Pay Now' : 'Next'}
					</Button>
				</Box>
			</ThemeProvider>
		</StyledEngineProvider>
	);
}

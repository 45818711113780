import React, { useState, useRef } from 'react';
import {
	Container,
	Grid,
	Typography,
	Box,
	Button,
	TextField,
	Snackbar,
	Alert,
} from '@mui/material';
import ReCAPTCHA from 'react-google-recaptcha';
import { BACKEND_URL, CAPTCHA_KEY } from './constants';

async function handleConfirmation(subject, emailAdress, message) {
	const config = {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			to: emailAdress,
			subject: `Your enquiry at Oxari has been received`,
			messages: `The contact form has been sent succesfully. 
			Subject: ${subject}, content: ${message}`,
		}),
	};
	try {
		await fetch(BACKEND_URL + '/contact/email', config);
	} catch (err) {
		console.log(err);
	}
}

async function handleContactForm(subject, emailAdress, name, message) {
	const config = {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			to: 'contact@oxari.io',
			subject: `${name} (${emailAdress}): ${subject}`,
			messages: message,
		}),
	};
	let response;
	try {
		response = await fetch(BACKEND_URL + '/contact/email', config);
	} catch (err) {
		console.log(err);
	}
	response.status === 200 ? handleConfirmation(subject, emailAdress, message) : <div></div>;
	return response.status;
}

export default function ContactForm() {
	const captchaRef = useRef(null);
	const [isDisabled, setIsDisabled] = useState(false);
	const [emailError, setEmailError] = useState('');

	const emailRegex =
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

	const handleSubmit = async (e) => {
		e.preventDefault();
		const token = captchaRef.current.getValue();
		// console.log('token: ', token);
		if (!token) {
			console.log('captcha not verified');
			return;
		}
		console.log('captcha verified');
		const dat = new FormData(e.currentTarget);
		const body = Object.fromEntries(dat);
		const email = body.email;
		if (!emailRegex.test(email)) {
			setEmailError('Invalid email address');
			setIsDisabled(false);
			return;
		} else {
			setEmailError('');
		}
		// console.log(body);
		let response = await handleContactForm(body.subject, body.email, body.name, body.message);
		// console.log('response: ', response);
		response === 200 ? setIsDisabled(true) : setIsDisabled(false);
		return;
	};

	return (
		<Container
			component='form'
			onSubmit={(e) => handleSubmit(e)}
			sx={{
				background: '#F5F5F5',
				padding: '2rem',
				position: 'absolute',
				top: '50%',
				left: '50%',
				transform: 'translate(-50%, -50%)',
				borderRadius: '20px',
				width: '60%',
			}}
		>
			<Box sx={{ paddingRight: '1rem', paddingLeft: '1rem' }}>
				<Box paddingBottom={3}>
					<Typography variant='pageHeader'>Contact Us</Typography>
				</Box>

				<Box paddingBottom={4} width='70%'>
					<Typography variant='h4'>
						If you want to use our data or want to get more information on any of our products.
					</Typography>
				</Box>
				<Grid container spacing={4} paddingBottom={3}>
					<Grid item md={12}>
						<TextField label='Subject' required id='subject' name='subject' fullWidth />
					</Grid>
					<Grid item md={12}>
						<TextField
							label='Email'
							required
							id='email'
							name='email'
							fullWidth
							error={!!emailError}
							helperText={emailError}
						/>
					</Grid>
					<Grid item md={12}>
						<TextField label='Name' required id='name' name='name' fullWidth />
					</Grid>
					<Grid item md={12}>
						<TextField
							label='Message'
							required
							id='message'
							name='message'
							fullWidth
							multiline
							rows={4}
						/>
					</Grid>
				</Grid>
				<Grid containe paddingTop={2}>
					<Grid item md={6}>
						<Snackbar open={isDisabled}>
							<Alert severity='success' sx={{ width: '100%' }}>
								Successfully signed up for the Oxari newsletter!
							</Alert>
						</Snackbar>
					</Grid>
					<Grid item md={6}>
						<Box display='flex' justifyContent='flex-end'>
							<ReCAPTCHA sitekey={CAPTCHA_KEY} ref={captchaRef} />
							<Button
								type='submit'
								variant='green'
								sx={{ minWidth: '183px', alignSelf: 'right' }}
								justifycontent='center'
								disabled={isDisabled}
							>
								SEND
							</Button>
						</Box>
					</Grid>
				</Grid>
			</Box>
		</Container>
	);
}

import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import './css/404.css';

// const jumbotronStyle = {
//     marginBottom: "0px",
//     backgroundColor: "lightgreen"
//   }

export class AccountConfirmationPage extends React.Component {
	render() {
		return (
			// <Jumbotron
			//   style={jumbotronStyle}
			// >
			<div className='jumbotronStyle jumbotron'>
				<Container>
					<Row>
						<Col sm={3}></Col>
						<Col>
							<h1 className='text-center'>Your account is active now. Happy?</h1>
							<p className='text-center'>Go ahead and sign in!</p>
						</Col>
						<Col sm={3}></Col>
					</Row>
				</Container>
			</div>
		);
	}
}

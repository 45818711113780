import React from 'react';
import { Typography, Card, CardMedia, Box, Collapse, Grid } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';

// const customStyles = {
// 	blurBackground: {
// 		position: 'relative',
// 		'&::after': {
// 			content: '""',
// 			display: 'block',
// 			position: 'absolute',
// 			top: 0,
// 			left: 0,
// 			width: '100%',
// 			height: '100%',
// 			filter: 'blur(25px)',
// 			zIndex: 1,
// 		},
// 	},
// 	image: {
// 		position: 'relative',
// 		zIndex: 0,
// 	},
// };
const alumniStyles = {
	// backgroundColor: 'rgba(255, 255, 255, 0.5)', // 50% opaque white
	// filter: 'blur(5px)',
	filter: 'blur(3px)',
};

const overlayStyle = {
	zIndex: 4,
	position: 'absolute',
	top: 0,
	left: 0,
	width: '100%',
	height: '100%',
	backgroundColor: 'rgba(128, 128, 128, 0.5)', // semi-transparent gray
};

function mapping(url, text = true) {
	const baseUrl = new URL(url).host;
	if (baseUrl.includes('linkedin.com'))
		return (
			<div>
				{text ? 'Linked' : ''}
				<FontAwesomeIcon icon={faLinkedinIn} />
			</div>
		);
	if (baseUrl.includes('github.com'))
		return (
			<div>
				{text ? 'Github' : ''}
				<FontAwesomeIcon icon={faGithub} />
			</div>
		);
}

export function TeamCard(props) {
	const {
		pic,
		name,
		description,
		contact_links,
		isAlumni,
		// achievements
	} = props;
	const [hover, setHover] = React.useState(true);
	return (
		<div onMouseEnter={() => setHover(false)} onMouseLeave={() => setHover(true)}>
			<Card
				sx={{
					minHeight: '385px',
					backgroundColor: '#39393A',
					boxShadow: 0,
				}}
			>
				<Box
					padding={2}
					marginRight={2}
					sx={{
						position: 'absolute',
						zIndex: 2,
						height: '385px',
					}}
				>
					<Typography
						variant='teamHeader'
						sx={{
							display: 'table-caption',
							color: 'white',
							paddingBottom: 2,
							width: 0.7,
						}}
					>
						{name}
					</Typography>
					<Typography variant='h4' sx={{ width: 0.6, color: 'white' }}>
						{description}
					</Typography>
					<Grid container>
						{contact_links.map((link, i) => (
							<Grid item p={1} key={`${link}-${i}`}>
								<a target='_blank' rel='noreferrer' href={link} className='fa-2x text-secondary'>
									{mapping(link, false)}
								</a>
							</Grid>
						))}
					</Grid>
					{/* {achievements && (
						<Grid container>
							{achievements.map((elem, i) => (
									<Typography key={i}>{elem}</Typography>
							))}
						</Grid>
					)} */}
				</Box>
				<Collapse in={hover} timeout='auto' mountOnEnter unmountOnExit>
					<div
						style={{
							position: 'relative',
							// width: 345,
						}}
					>
						<CardMedia
							sx={{
								position: 'relative',
								zIndex: 3,
								...(isAlumni && alumniStyles),
							}}
							component='img'
							image={pic}
							// width='329px'
							height='385px'
						/>
						{isAlumni && <div style={overlayStyle}></div>}
					</div>
				</Collapse>
			</Card>
		</div>
	);
}

import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

const steps = ['Billing Information', 'Review and Confirm', 'Payment'];

export default function HorizantalStepper({ stepNumber }) {
	return (
		<Box sx={{ width: '100%', marginTop: '20px' }}>
			<Stepper activeStep={stepNumber} alternativeLabel color={'green'}>
				{steps.map((label) => (
					<Step key={label}>
						<StepLabel>{label}</StepLabel>
					</Step>
				))}
			</Stepper>
		</Box>
	);
}

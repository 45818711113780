import React, { useState, useEffect } from 'react';
import Pagination from '@mui/material/Pagination';
import { Container, Typography, Grid, Box, Card, CardMedia } from '@mui/material';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';

const BLOG_URL = process.env.REACT_APP_STRAPI_BLOG_URL || 'http://localhost:1337/api';
// const DO_PREFIX = process.env.REACT_APP_DO_PREFIX || '';

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '90vw',
	bgcolor: 'background.paper',
	p: 4,
	border: '0.5px solid #000',
	borderRadius: '10px',
	overflow: 'auto',
	maxHeight: '80vh',
	boxShadow: 24,
	whiteSpace: 'pre-line',

	'@media (min-width: 1200px)': {
		width: '900px',
	},

	'& > #modal-close-btn': {
		display: 'block',
		marginLeft: 'auto',
		marginTop: 'auto',
	},
};

const options = { year: 'numeric', month: 'long', day: 'numeric' };

export default function Newsfeed() {
	const [newsItems, setNewsItems] = useState([]);
	const [page, setPage] = useState(1);
	const newsItemsPerPage = 3;
	const theme = useTheme();

	const [totalItems, setTotalItems] = useState(1);

	const [open, setOpen] = React.useState(false);
	const handleClose = () => setOpen(false);

	const [selectedNewsItem, setSelectedNewsItem] = useState(null);

	useEffect(() => {
		getNewsItems(page);
	}, [page]);

	async function getNewsItems(page) {
		try {
			const response = await fetch(
				`${BLOG_URL}/news-items?populate=picture&pagination[page]=${page}&pagination[pageSize]=${newsItemsPerPage}&sort=publishedAt:desc`
			);

			const { data, meta } = await response.json();
			setTotalItems(meta.pagination.total);
			setNewsItems(data);
		} catch (err) {
			console.error(err);
		}
	}

	const handlePageChange = (event, value) => {
		setPage(value);
	};

	// Function to handle opening the modal for a specific grid item
	const handleGridItemClick = (newsItem) => {
		setSelectedNewsItem(newsItem); // Set the selected item
		setOpen(true); // Open the modal
	};

	return (
		<Container sx={{ paddingBottom: 6 }}>
			<Box paddingTop={6} paddingBottom={2}>
				<Typography variant='h4' fontWeight='bold' mb={2}>
					Newsfeed
				</Typography>
			</Box>

			<Box sx={{ backgroundColor: 'white', paddingTop: 2, paddingBottom: 2, borderRadius: '10px' }}>
				{newsItems &&
					newsItems.map((newsItem) => (
						<Container fluid={true} key={newsItem.id}>
							<Grid
								container
								spacing={3}
								padding={2}
								justifyContent='center'
								onClick={() => handleGridItemClick(newsItem)}
								sx={{ cursor: 'pointer' }}
							>
								{newsItem.attributes.picture && (
									<Grid item md={2} xs={10}>
										<Card sx={{ borderRadius: '10px', borderColor: 'primary.main' }}>
											<CardMedia
												component='img'
												image={newsItem.attributes.picture.data.attributes.formats.small.url}
												alt={newsItem.attributes.title}
											/>
										</Card>
									</Grid>
								)}
								<Grid
									item
									lg={newsItem.attributes.picture ? 10 : 12}
									md={newsItem.attributes.picture ? 10 : 12}
									xs={10}
								>
									<Box paddingBottom={2}>
										<Typography variant='subtitle1' fontWeight='bold'>
											{newsItem.attributes.title}
										</Typography>
									</Box>

									<Box paddingBottom={2}>
										<Typography variant='body2'>{newsItem.attributes.description}</Typography>
									</Box>

									<Typography
										variant='subtitle2'
										color={theme.palette.primary.bright_green}
										fontWeight='bold'
									>
										{new Date(newsItem.attributes.publishedAt).toLocaleDateString(
											undefined,
											options
										)}
									</Typography>
								</Grid>
							</Grid>
						</Container>
					))}
			</Box>

			<Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
				<Pagination
					count={Math.ceil(totalItems / newsItemsPerPage)}
					page={page}
					onChange={handlePageChange}
					color='primary'
				/>
			</Box>

			{/* Modal */}
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
			>
				<Grid container sx={style} spacing={2}>
					<Grid item md={12}>
						<Typography id='modal-modal-title' variant='h4' fontWeight='bold'>
							{selectedNewsItem?.attributes.title || ''}
						</Typography>
					</Grid>
					<Grid item md={3} xs={12}>
						<Card
							sx={{
								borderRadius: '10px',
								maxHeight: '500px',
								maxWidth: '500px',
								margin: '20px auto 10px auto',
							}}
							id='modal-modal-img'
						>
							<CardMedia
								component='img'
								image={selectedNewsItem?.attributes.picture.data.attributes.formats.small.url}
								alt={selectedNewsItem?.attributes.title}
							/>
						</Card>
					</Grid>
					<Grid item md={9} xs={12} container direction='column' justifyContent='space-evenly'>
						<Typography sx={{ mt: 2 }} variant='h6'>
							{selectedNewsItem?.attributes.description || ''}
						</Typography>
						<Typography sx={{ mt: 2 }} variant='body2'>
							{new Date(selectedNewsItem?.attributes.publishedAt).toLocaleDateString(
								undefined,
								options
							) || ''}
						</Typography>
					</Grid>
					<Grid item md={12}>
						<Typography sx={{ mt: 2 }} variant='body1'>
							{selectedNewsItem?.attributes.content || ''}
						</Typography>
					</Grid>

					<Grid item container justifyContent='flex-end'>
						<Button
							id='modal-close-btn'
							variant='contained'
							color='success'
							onClick={handleClose}
							sx={{
								background: theme.palette.primary.button_and_highlights,
								color: 'black',
								'&:hover': {
									background: theme.palette.primary.main,
									color: '#39393A',
								},
							}}
						>
							Close
						</Button>
					</Grid>
				</Grid>
			</Modal>
		</Container>
	);
}

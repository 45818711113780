import React from 'react';
import { Container, List, ListItem } from '@mui/material';
import Typography from '@mui/material/Typography';
import {
	Timeline,
	TimelineItem,
	TimelineSeparator,
	TimelineConnector,
	TimelineContent,
	TimelineOppositeContent,
	TimelineDot,
} from '@mui/lab';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SmartToyOutlinedIcon from '@mui/icons-material/SmartToyOutlined';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import CheckIcon from '@mui/icons-material/Check';
import HardwareIcon from '@mui/icons-material/Hardware';
import ApartmentIcon from '@mui/icons-material/Apartment';
import HandshakeIcon from '@mui/icons-material/Handshake';
import DeviceThermostatIcon from '@mui/icons-material/DeviceThermostat';
import BuildIcon from '@mui/icons-material/Build';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';

export function OxariTimeline() {
	return (
		<Container
			sx={{
				minWidth: '100%',
				backgroundColor: 'white',
				paddingTop: 5,
				paddingBottom: 5,
			}}
		>
			<Container>
				<Timeline position='alternate'>
					<TimelineItem>
						<TimelineOppositeContent sx={{ m: 'auto 0' }} variant='body2' color='text.secondary'>
							February 2020
						</TimelineOppositeContent>
						<TimelineSeparator>
							<TimelineConnector />
							<TimelineDot color='primary'>
								<PlayArrowIcon />
							</TimelineDot>
							<TimelineConnector />
						</TimelineSeparator>
						<TimelineContent sx={{ py: '12px', px: 2 }}>
							<Typography variant='h4' component='span'>
								Project Initiation
							</Typography>
							<Typography>
								The idea formed in the hope of making carbon emission data available to everyone
							</Typography>
						</TimelineContent>
					</TimelineItem>

					<TimelineItem>
						<TimelineOppositeContent sx={{ m: 'auto 0' }} variant='body2' color='text.secondary'>
							March 2020
						</TimelineOppositeContent>
						<TimelineSeparator>
							<TimelineConnector />
							<TimelineDot color='primary'>
								<SmartToyOutlinedIcon />
							</TimelineDot>
							<TimelineConnector />
						</TimelineSeparator>
						<TimelineContent sx={{ py: '12px', px: 2 }}>
							<Typography variant='h4' component='span'>
								First Machine Learning Model
							</Typography>
							<Typography>The first version of the ML model was created</Typography>
						</TimelineContent>
					</TimelineItem>

					<TimelineItem>
						<TimelineOppositeContent sx={{ m: 'auto 0' }} variant='body2' color='text.secondary'>
							April 2020
						</TimelineOppositeContent>
						<TimelineSeparator>
							<TimelineConnector />
							<TimelineDot color='primary'>
								<EmojiEventsIcon />
							</TimelineDot>
							<TimelineConnector />
						</TimelineSeparator>
						<TimelineContent sx={{ py: '12px', px: 2 }}>
							<Typography variant='h4' component='span'>
								Best Idea Award
							</Typography>
							<Typography>
								{`The project won "best idea" prize in the Sustainable Entrepreneurship pitch contest	at Utrecht University`}
							</Typography>
						</TimelineContent>
					</TimelineItem>

					<TimelineItem>
						<TimelineOppositeContent sx={{ m: 'auto 0' }} variant='body2' color='text.secondary'>
							February 2021
						</TimelineOppositeContent>
						<TimelineSeparator>
							<TimelineConnector />
							<TimelineDot color='primary'>
								<CheckIcon />
							</TimelineDot>
							<TimelineConnector />
						</TimelineSeparator>
						<TimelineContent sx={{ py: '12px', px: 2 }}>
							<Typography variant='h4' component='span'>
								First Prototype
							</Typography>
							<Typography>
								Release of the Oxari website together with the first version of the Emission Model
								tool
							</Typography>
						</TimelineContent>
					</TimelineItem>

					<TimelineItem>
						<TimelineOppositeContent sx={{ m: 'auto 0' }} variant='body2' color='text.secondary'>
							March 2021
						</TimelineOppositeContent>
						<TimelineSeparator>
							<TimelineConnector />
							<TimelineDot color='primary'>
								<HardwareIcon />
							</TimelineDot>
							<TimelineConnector />
						</TimelineSeparator>
						<TimelineContent sx={{ py: '12px', px: 2 }}>
							<Typography variant='h6' component='span'>
								Oxari BV
							</Typography>
							<Typography>Legal funding of the Oxari BV company</Typography>
						</TimelineContent>
					</TimelineItem>

					<TimelineItem>
						<TimelineOppositeContent sx={{ m: 'auto 0' }} variant='body2' color='text.secondary'>
							August 2021
						</TimelineOppositeContent>
						<TimelineSeparator>
							<TimelineConnector />
							<TimelineDot color='primary'>
								<ApartmentIcon />
							</TimelineDot>
							<TimelineConnector />
						</TimelineSeparator>
						<TimelineContent sx={{ py: '12px', px: 2 }}>
							<Typography variant='h4' component='span'>
								MIT subsidy
							</Typography>
							<Typography>
								Received subsidy from the Dutch Government to study and develop a Climate Score
							</Typography>
						</TimelineContent>
					</TimelineItem>

					<TimelineItem>
						<TimelineOppositeContent sx={{ m: 'auto 0' }} variant='body2' color='text.secondary'>
							October 2021
						</TimelineOppositeContent>
						<TimelineSeparator>
							<TimelineConnector />
							<TimelineDot color='primary'>
								<HandshakeIcon />
							</TimelineDot>
							<TimelineConnector />
						</TimelineSeparator>
						<TimelineContent sx={{ py: '12px', px: 2 }}>
							<Typography variant='h4' component='span'>
								CCC Membership
							</Typography>
							<Typography>Became a member of the Climate Chain Coalition (CCC)</Typography>
						</TimelineContent>
					</TimelineItem>

					<TimelineItem>
						<TimelineOppositeContent sx={{ m: 'auto 0' }} variant='body2' color='text.secondary'>
							May 2022
						</TimelineOppositeContent>
						<TimelineSeparator>
							<TimelineConnector />
							<TimelineDot color='primary'>
								<DeviceThermostatIcon />
							</TimelineDot>
							<TimelineConnector />
						</TimelineSeparator>
						<TimelineContent sx={{ py: '12px', px: 2 }}>
							<Typography variant='h4' component='span'>
								Temperature Score
							</Typography>
							<Typography>
								Release of the temperature score dashboard for public companies based on the WWF and
								CDP methodology
							</Typography>
						</TimelineContent>
					</TimelineItem>

					<TimelineItem>
						<TimelineOppositeContent sx={{ m: 'auto 0' }} variant='body2' color='text.secondary'>
							August 2022
						</TimelineOppositeContent>
						<TimelineSeparator>
							<TimelineConnector />
							<TimelineDot color='primary'>
								<ApartmentIcon />
							</TimelineDot>
							<TimelineConnector />
						</TimelineSeparator>
						<TimelineContent sx={{ py: '12px', px: 2 }}>
							<Typography variant='h4' component='span'>
								MIT subsidy
							</Typography>
							<Typography>
								Received a subsidy from the Dutch Government to study and develop a Climate Score
							</Typography>
						</TimelineContent>
					</TimelineItem>

					<TimelineItem>
						<TimelineOppositeContent sx={{ m: 'auto 0' }} variant='body2' color='text.secondary'>
							2023
						</TimelineOppositeContent>
						<TimelineSeparator>
							<TimelineConnector />
							<TimelineDot color='primary'>
								<BuildIcon />
							</TimelineDot>
							<TimelineConnector />
						</TimelineSeparator>
						<TimelineContent sx={{ py: '12px', px: 2 }}>
							<Typography variant='h4' component='span'>
								Dashboard & Model Improvement
							</Typography>
							<Typography>
								Use funding to start developing new tools and further improve the underlying
								machine-learning pipeline
							</Typography>
						</TimelineContent>
					</TimelineItem>

					<TimelineItem>
						<TimelineOppositeContent sx={{ m: 'auto 0' }} variant='body2' color='text.secondary'>
							August 2023
						</TimelineOppositeContent>
						<TimelineSeparator>
							<TimelineConnector />
							<TimelineDot color='primary'>
								<MeetingRoomIcon />
							</TimelineDot>
							<TimelineConnector />
						</TimelineSeparator>
						<TimelineContent sx={{ py: '12px', px: 2 }}>
							<Typography variant='h4' component='span'>
								Beta Release
							</Typography>
							<Typography>Start selling the product to investors</Typography>
						</TimelineContent>
					</TimelineItem>

					<TimelineItem>
						<TimelineOppositeContent sx={{ m: 'auto 0' }} variant='body2' color='text.secondary'>
							Future
						</TimelineOppositeContent>
						<TimelineSeparator>
							<TimelineConnector />
							<TimelineDot color='primary'>
								<AddShoppingCartIcon />
							</TimelineDot>
							<TimelineConnector />
						</TimelineSeparator>
						<TimelineContent sx={{ py: '12px', px: 2 }}>
							<Typography variant='h4' component='span'>
								Future Products & Ideas
							</Typography>

							<List
								sx={{
									listStyleType: 'disc',
									pl: 4,
									direction: 'rtl',
									paddingRight: 3,
								}}
							>
								<ListItem
									sx={{ display: 'list-item', paddingTop: 0, paddingBottom: 0, textAlign: 'right' }}
								>
									Finance APIs integration
								</ListItem>
								<ListItem
									sx={{ display: 'list-item', paddingTop: 0, paddingBottom: 0, textAlign: 'right' }}
								>
									Finance & ESG Statements Reader
								</ListItem>
								<ListItem
									sx={{ display: 'list-item', paddingTop: 0, paddingBottom: 0, textAlign: 'right' }}
								>
									Sustainable Portfolio Analysis
								</ListItem>
								<ListItem
									sx={{ display: 'list-item', paddingTop: 0, paddingBottom: 0, textAlign: 'right' }}
								>
									Green Investment Bot
								</ListItem>
								<ListItem
									sx={{ display: 'list-item', paddingTop: 0, paddingBottom: 0, textAlign: 'right' }}
								>
									Decentralized Finance Apps
								</ListItem>
							</List>
						</TimelineContent>
					</TimelineItem>
				</Timeline>
			</Container>
		</Container>
	);
}

import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Carousel from 'react-material-ui-carousel';
import { useTheme } from '@mui/material/styles';
import { testimonials } from '../misc/text/home';

function Item({ item }) {
	const theme = useTheme();
	const quoteOffset = theme.spacing(1).split('px')[0];

	return (
		<Box>
			<Typography
				variant='body1'
				sx={{
					padding: 2,
					pr: 0,
					'&::before, &::after': {
						position: 'absolute',
						fontSize: theme.typography.h2.fontSize,
						color: theme.palette.primary.bright_green,
					},
					'&::before': {
						content: '"❝"',
						top: quoteOffset * -1,
						left: quoteOffset * -0.5,
					},
					'&::after': {
						content: '"❞"',
						right: quoteOffset * -0.5,
					},
				}}
			>
				{item.text}
			</Typography>
			<Typography
				variant='subtitle1'
				fontWeight={'bold'}
				display='flex'
				justifyContent={'flex-end'}
				alignItems={'center'}
				textAlign='right'
				gap={2}
			>
				- {item.author}
				<img src={item.image} alt={item.alt} width={item.width} height={item.height} />
			</Typography>
		</Box>
	);
}

export default function TestimonialsCarousel() {
	return (
		<Carousel
			autoPlay={true}
			swipe={true}
			interval={10 * 1000}
			indicators={false}
			animation={'fade'}
			fullHeightHover={false}
			sx={{
				width: { xs: '85vw', md: '90vw' },
				maxWidth: '1100px',
			}}
			className={'testimonials-carousel'}
		>
			{testimonials.map((item, i) => (
				<Item key={`testimonialsItem${i}`} item={item} />
			))}
		</Carousel>
	);
}

import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import pic from './../imgs/empty.webp';
import './css/404.css';

const imageStyle = {
	borderStyle: 'solid',
	borderColor: 'darkgreen',
	borderWidth: '0.5rem',
	width: '100%',
};

// const jumbotronStyle = {
//     marginBottom: "0px",
//     backgroundColor: "lightgreen"
//   }

export class PageNotFoundPage extends React.Component {
	render() {
		return (
			<div className='jumbotron jumbotronStyle'>
				<Container>
					<Row>
						<Col sm={3}></Col>
						<Col>
							<h1 className='text-center'>404 Error</h1>
							<p className='text-center'>Ups! We could not find the page you were looking for...</p>
						</Col>
						<Col sm={3}></Col>
					</Row>
					<Row>
						<Col></Col>
						<Col>
							<img src={pic} alt='' style={imageStyle} />
						</Col>
						<Col></Col>
					</Row>
				</Container>
			</div>
		);
	}
}

import React from 'react';
import {
	Card,
	CardHeader,
	CardFooter,
	CardBody,
	Input,
	Label,
	Row,
	Col,
	Container,
	FormGroup,
} from 'reactstrap';

export default function NewsletterPage() {
	return (
		<>
			<Container fluid>
				<Row>
					<Col md='3'></Col>
					<Col xs='auto'>
						<Card className='my-2'>
							<form
								action='https://oxari.us21.list-manage.com/subscribe/post?u=6a0ae82cb8f30d17bb3110eea&id=945e664833&f_id=003...'
								method='post'
								id='mc-embedded-subscribe-form'
								name='mc-embedded-subscribe-form'
								className='validate'
								target='_blank'
								noValidate
							>
								<CardHeader>
									<h2>Sign up for our Newsletter!</h2>
									<p>
										Subscribe to our newsletter to get updated on news and product developments.
									</p>
								</CardHeader>
								<CardBody>
									{/* <!-- Begin Mailchimp Signup Form --> */}

									<div id='mc_embed_signup_scroll'>
										{/* <h2>Subscribe</h2> */}

										<FormGroup tag='div' className='mc-field-group'>
											<Label for='mce-EMAIL'>
												Email Address <span className='asterisk'>*</span>
											</Label>

											{/* <Input type="email" /> */}
											<Input
												type='email'
												name='EMAIL'
												className='required email'
												id='mce-EMAIL'
												required
											/>

											<span id='mce-EMAIL-HELPERTEXT' className='helper_text'></span>
										</FormGroup>

										<FormGroup className='mc-field-group'>
											<Label for='mce-FNAME'>First Name </Label>

											<Input type='text' name='FNAME' className='' id='mce-FNAME' />

											<span id='mce-FNAME-HELPERTEXT' className='helper_text'></span>
										</FormGroup>

										<FormGroup className='mc-field-group'>
											<Label for='mce-LNAME'>Last Name </Label>

											<Input type='text' name='LNAME' className='' id='mce-LNAME' />

											<span id='mce-LNAME-HELPERTEXT' className='helper_text'></span>
										</FormGroup>
										<div className='indicates-required'>
											<span className='asterisk'>*</span> indicates required
										</div>
										<div id='mce-responses' className='clear'>
											<div
												className='response'
												id='mce-error-response'
												style={{ display: 'none' }}
											></div>

											<div
												className='response'
												id='mce-success-response'
												style={{ display: 'none' }}
											></div>
										</div>
										{/* <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups--> */}

										<div
											style={{
												position: 'absolute',
												left: '-5000px',
											}}
											aria-hidden='true'
										>
											<Input
												type='text'
												name='b_6a0ae82cb8f30d17bb3110eea_945e664833'
												tabIndex='-1'
												value=''
												onChange={(ev) => ev}
											/>
										</div>
									</div>
								</CardBody>
								<CardFooter>
									<Input
										type='submit'
										value='Subscribe'
										name='subscribe'
										id='mc-embedded-subscribe'
										className='btn-primary'
									/>
								</CardFooter>
							</form>
						</Card>
					</Col>
					<Col md='3'></Col>
				</Row>
			</Container>
		</>
	);
}

import React from 'react';
import { Row, Col, Container } from 'reactstrap';
import logo from '../imgs/oxari.webp';
import { animateScroll as scroll } from 'react-scroll';
import './css/termsofuse.css';
import { termsOfUseContent } from '../misc/text/termsofuse';

class TermsOfUsePage extends React.Component {
	constructor() {
		super();
		this.scrollToTop = this.scrollToTop.bind(this);
	}

	scrollToTop() {
		scroll.scrollToTop();
	}

	componentDidMount() {
		this.scrollToTop();
	}

	render() {
		return (
			<Container className='my-5'>
				<Row id='termsofuse-intro'>
					<Col lg='6'>
						<img className='img-fluid d-none d-lg-block' src={logo} alt='Logo' />
					</Col>
					<Col sm='12' lg='6' className='d-flex align-items-center'>
						<h5 className='section-paragraph px-3 px-md-auto'>
							By accessing this website you agree to be bound by these Terms of Use and agree that
							you are responsible for the agreement with any applicable local laws and regulations.
							If you disagree with these Terms of Use, you are prohibited from accessing this
							website and using the tools. The materials on this website are protected by copyright
							and trade mark law.
						</h5>
					</Col>
				</Row>

				<Row className='py-4'>
					{termsOfUseContent.map((el, i) => (
						<div key={i} className='w-100'>
							<h2 className='page-title'>{el.title}</h2>
							<div className='page-text'>
								{el.body.map((list, j) => {
									if (typeof list === 'string') return <p key={j}>{list}</p>;
									return (
										<ul key={j}>
											{list.map((listitem, k) => (
												<li key={k}>{listitem}</li>
											))}
										</ul>
									);
								})}
							</div>
							<hr
								style={{
									height: '4px',
									margin: '32px 0px',
								}}
							/>
						</div>
					))}
				</Row>
			</Container>
		);
	}
}

export default TermsOfUsePage;
